const start = () => {
  document.getElementsByClassName('spinner')[0].classList.add('spinner--show')
}

const stop = () => {
  document
    .getElementsByClassName('spinner')[0]
    .classList.remove('spinner--show')
}

const spinner = {
  start,
  stop,
}

export default spinner
