export default {}

/*******************************************/
/* FIND NEXT VALID INDEX WITHIN ITEM ARRAY */
/*******************************************/
export function getNextValidIndex(arr, currentIndex) {
  if (currentIndex === undefined) currentIndex = -1

  let nextIndex

  arr.forEach((item, index) => {
    if (nextIndex !== undefined) return
    if (index <= currentIndex) return
    if (item.isHidden || item.isHeader) return

    nextIndex = index
  })

  return nextIndex
}

/***********************************************/
/* FIND PREVIOUS VALID INDEX WITHIN ITEM ARRAY */
/***********************************************/
export function getPreviousValidIndex(arr, currentIndex) {
  let previousIndex

  arr.forEach((item, index) => {
    if (index >= currentIndex) return
    if (item.isHidden || item.isHeader) return

    previousIndex = index
  })

  return previousIndex
}

/********************************************/
/* FIND FIRST VALID INDEX WITHIN ITEM ARRAY */
/********************************************/
export function getFirstValidIndex(arr) {
  let firstIndex
  arr.forEach((item, index) => {
    if (firstIndex !== undefined) return
    if (item.isHidden || item.isHeader) return

    firstIndex = index
  })
  return firstIndex
}

/*******************************************/
/* FIND LAST VALID INDEX WITHIN ITEM ARRAY */
/*******************************************/
export function getLastValidIndex(arr) {
  let lastIndex
  arr.forEach((item, index) => {
    if (item.isHidden || item.isHeader) return

    lastIndex = index
  })
  return lastIndex
}
